import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../Generic/Button/Button';
import TextInput from '../../Generic/TextInput/TextInput';
import './add-access.scss';
import {toast} from 'sonner';
import Dropdown from '../../Generic/Dropdown/Dropdown';
import { addGateCred } from '../../../API/Gates';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { GateList } from '../GateList/GateList';
import { Switch } from 'antd';

export interface IAddNewCredentialProps {
}

export const AddNewCredential = (props: IAddNewCredentialProps) => {
    const {globalState:{user, gates}} = useContext(GlobalStateContext);
    const [META_DATA, setMETA_DATA] = useState(user.application_configs);

    const form = {
        role: 'Guard',
        gate_number: '',
        username: '',
        password: '',
        is_enabled: true,
      };
    const [formData, setFormData] = React.useState(form);
    const [invalidFields, setInvalidFields] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);

    const validateFields = React.useCallback(async (valid: boolean, field: string) => {

        if(valid) {
            if(invalidFields.includes(field))
            setInvalidFields(Fields => { 
                let filtered = Fields.filter( value => value !== field );
                return filtered;
            });
        }
        else {
            if(!invalidFields.includes(field))
                setInvalidFields(Fields => { 
                    Fields.push(field);
                    return Fields;
                });
        }
      },[]);

      const submitForm = async () => {
        try {
          if(invalidFields.length) {
            toast.warning('Please fill all the required fields!');
            setSubmitClicked(true);
            return;
        }
          const payload = {
            gate_number: formData.gate_number,
            username: formData.username,
            password: formData.password,
            role: formData.role,
            company_id: user.company_id,
            is_notification_enabled: formData.is_enabled

          }
          const response = await addGateCred(payload);
          
          if(response && response.statusCode === 200) {
            toast.success(response.message)
            setFormData(form);
          }
          else throw new Error(response.message);
          
        }
        catch(err) {
          toast.error(err.message)
        }
      }

      const getGates = () => {
        let gateNumbers = [];
        gates && gates.forEach(gate => {
          gateNumbers.push(gate.gate_number);
        });

        return gateNumbers;
      }

  return (
    <div className={'new-access-form-container'}>
        <div className={'title-left'}>
            Add New Credential
        </div>
        <div style={{marginTop:'2rem', display:'flex', gap:'2rem'}}>
            <Dropdown
                label={'Gate number'}
                placeholder={'Select gate'}
                width={'30%'}
                options={getGates()}
                onSelect={(value)=>setFormData({...formData, gate_number: value})}
                selection={formData.gate_number}
                isRequired={true}
                isValid={validateFields}
                showWarning={submitClicked}
            />
        </div>
        <div style={{marginTop:'2rem', display:'flex', gap:'2rem'}}>
            <TextInput
                label={'User name'}
                placeholder={'Enter username'}
                width={'30%'}
                isRequired={true}
                isValid={validateFields}
                showWarning={submitClicked}
                onChange={(value) => setFormData({...formData, username: value})}
                value={formData.username}
            />
            <TextInput
                label={'Password'}
                placeholder={'Enter password'}
                width={'30%'}
                isRequired={true}
                isValid={validateFields}
                showWarning={submitClicked}
                onChange={(value) => setFormData({...formData, password: value})}
                value={formData.password}
            />
        </div>
        <div>
              <Switch
                style={{ margin: '1rem' }}
                value={formData.is_enabled}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    is_enabled: value
                  });
                }}
              />
              Enable Notifications
        </div>
        <div style={{justifyContent:'center', display:'flex', margin:'2rem 2rem 0 30rem'}}>
            <Button
            class='btn-container'
                bgcolor={'#28C670'}
                textcolor={'white'}
                text={'Add access'}
                width={'fit-content'}
                onClick={submitForm}
            />
        </div>

        {/* <GateList/> */}
    </div>
  );
}
