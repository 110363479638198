export const ROUTES = {
  home: '/',
  visitor: '/visitors',
  gates: '/gates',
  cameras: '/cameras',
  configs: '/configs',
  notifications: '/notifications',
  visitorDetails: '/visitor-details',
  gateDetails: '/gate-details',
  login: '/log-in',
  user: '/user-company',
  updateVisitor: '/update-visitor',
  updateActivty: '/update-activity',
  updateGate: '/update-gate',
  updateCamera: '/update-camera',
  updateCategory: '/update-category',
  updateUnit: '/update-unit',
  updateUser: '/update-user'
}

export const sideNavBarRoutes = [
    {
      routeImage: '/images/side_bar/visitors-side-bar.svg',
      routeName: "Visitors",
      routePath : ROUTES.visitor,
      accessName: 'VISITOR_TAB'
    },
    {
      routeImage: '/images/side_bar/gate.svg',
      routeName: "Gates",
      routePath : ROUTES.gates,
      accessName: 'GATE_TAB'
    },
    
    {
      routeImage: '/images/side_bar/cam.svg',
      routeName: "Cameras",
      routePath : ROUTES.cameras,
      accessName: 'CAMERA_TAB'
    },
     {
      routeImage: '/images/side_bar/configs.svg',
      routeName: "Configs",
      routePath : ROUTES.configs,
      accessName: 'CONFIGS_TAB'
    },
    {
      routeImage: '/images/side_bar/bell.svg',
      routeName: "Notification",
      routePath : ROUTES.notifications,
      accessName: 'NOTIFICATION_TAB'
    }
];

