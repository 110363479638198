import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../../Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../../Generic/Dropdown/Dropdown';
import TextInput from '../../../Generic/TextInput/TextInput';
import './edit-category.scss';
import { toast } from 'sonner';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { ROUTES } from '../../../Routes/SideBarRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategory, updateCategory } from '../../../../API/Configs';
import BasicTimePicker, { BasicTimePickerMMSS } from '../../../Generic/TimePicker/TimerPicker';

export const IpV4RegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IpV6RegEx = /((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}/;

export const EditCategory = () => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);
  const navigate = useNavigate();

  const { id } = useParams();


  const [formData, setFormData] = useState({
    title: null,
    description: null,
    cross_duration: null,
    exit_time: null,
    gate_numbers: null,
  });

  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const handleClick = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }
      const payload = { ...formData,
        gate_numbers: formData.gate_numbers?.split(",")

      };
      const response = await updateCategory(id,payload);

      if (response && response.statusCode === 200) {
        // props.moveToListPage();
        toast.success("Success: Category added!");
        navigate(ROUTES.configs);
      }
      else throw new Error("Error");
    }
    catch (err) {
      toast.error("Error: failed to add Category!");
    }
  }

  const getGates = () => {
    let gateNumbers = [];
    gates && gates.forEach(gate => {
      gateNumbers.push(gate.gate_number);
    });

    return gateNumbers;
  }

  React.useEffect(()=>{
    getCategory(id).then(res=> setFormData({
      title: res.response.title,
      description: res.response.description,
      cross_duration: res.response.cross_duration,
      exit_time: res.response.exit_time,
      gate_numbers: res.response.gate_numbers?.toString(),      
    }))
  },[]);


  return (
    <div className={'edit-category-form-container'}>
      <div className={'title-left'}>
        Edit Category
      </div>
      <div className={'add-category-form-row-1'}>
        <TextInput
          label={'Category title'}
          placeholder={'Enter category title'}
          width={'30%'}
          onChange={(value) => setFormData({ ...formData, title: value })}
          value={formData.title}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
          disabled={true}
        />

        <TextInput
          label={'Description'}
          placeholder={'Enter description'}
          width={'30%'}
          onChange={(value) => setFormData({ ...formData, description: value })}
          value={formData.description}
          isRequired={false}
          // isValid={validateFields}
          // showWarning={submitClicked}
        />



      </div>
      <div className='category-rules'>
        <h3 >Add Category Rules</h3>
      </div>
      
      <div className={'add-category-form-row-2'}>

        <BasicTimePickerMMSS label="select the cross duration (mm:ss)" value={formData.cross_duration} setValue={(selection) => setFormData({ ...formData, cross_duration: selection, exit_time: '' })} />
        <BasicTimePicker label="select the exit time (hh:mm)" value={formData.exit_time} setValue={(selection) => setFormData({ ...formData, exit_time: selection, cross_duration: '' })} />
    
      </div>

      
      <div style={{margin:'2em 0'}}>
      <Dropdown
          label={'Notification of Violation to Gates'}
          placeholder={'Select gate'}
          width={'30%'}
          options={getGates()}
          onSelect={(value) => setFormData({ ...formData, gate_numbers: value })}
          selection={formData.gate_numbers}
          isRequired={false}
          // isValid={validateFields}
          // showWarning={submitClicked}
          variant={DropDownVariant.multiValue}
        />
      </div>

      <div className={'btn-position'}>
        <Button
        class='btn-container'
          bgcolor={'#28C670'}
          text={'Update Category'}
          textcolor={'white'}
          width={''}
          onClick={handleClick}
        />
      </div>

    </div>
  );
};
