import { API_METHOD, MASTER_BASE_URL, FetchMaster, MASTER, SLAVE_BASE_URL } from "./FetchMaster";
export const prepareUrl = (url, options) => {
    let preparingUrl = url;
    if(options.query) preparingUrl = preparingUrl + `&query=${options.query}`;
    if(options.start_date) preparingUrl = preparingUrl + `&start_date=${options.start_date}`;
    if(options.end_date) preparingUrl = preparingUrl + `&end_date=${options.end_date}`;
    if(options.unit) preparingUrl = preparingUrl + `&unit=${options.unit}`;
    if(options.category) preparingUrl = preparingUrl + `&category=${options.category}`;
    if(options.activity) preparingUrl = preparingUrl + `&activity=${options.activity}`;
    if(options.gate_number) preparingUrl = preparingUrl + `&gate_number=${options.gate_number}`;
    if(options.user_id) preparingUrl = preparingUrl + `&user_id=${options.user_id}`;
    if(options.limit) preparingUrl = preparingUrl + `&limit=${options.limit}`;
    console.log(options)
    console.log(prepareUrl)
    return preparingUrl;
}

export const getAllVisitors = (fetchOptions) => {
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/visitors?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET)
}

export const createVisitor = (payload) => {
    let url = `${MASTER_BASE_URL}/visitors`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}
//activities

export const createVisitorActivity = (payload) => {
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/visitors/activities`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const getVisitorActivities = (fetchOptions) => {
    
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/visitors/activities?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}


export const getVisitorActivityById = (id) => {
    
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/visitors/activities/${id}`;
    return FetchMaster(url, API_METHOD.GET);
}

export const updateVisitorActivity = (id, payload) => {
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/visitors/activities/${id}`;
    return FetchMaster(url, API_METHOD.PUT, JSON.stringify(payload));
}


export const getVisitorActivitiesByGate = (fetchOptions) => {

    let url = `${MASTER_BASE_URL}/visitors/activities?currentPage=${fetchOptions.page}&gate_number=${fetchOptions.gate_number}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const getVisitorActivitiesByVisitor = (fetchOptions) => {
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/visitors/activities?currentPage=${fetchOptions.page}&visitor_id=${fetchOptions.visitor_id}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const getVisitorById = (visitor_id) => {
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/visitors/details?visitor_id=${visitor_id}`;
    return FetchMaster(url, API_METHOD.GET);
}

export const updateVisitor = (visitor_id, payload) => {
    let url = `${MASTER_BASE_URL}/visitors/${visitor_id}`;
    return FetchMaster(url, API_METHOD.PUT, JSON.stringify(payload));
}
export const disableVisitor = (visitor_id, block) => {
    let url = `${MASTER_BASE_URL}/visitors/${visitor_id}?block=${block}`;
    return FetchMaster(url, API_METHOD.DELETE);
}

export const deleteVisitorActivity = (id) => {

    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/visitors/activities/${id}`;
    
    return FetchMaster(url, API_METHOD.DELETE);

}