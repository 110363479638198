import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { Button } from '../../../Generic/Button/Button';
import { ROUTES } from '../../../Routes/SideBarRoutes';
import './camera-details.scss';
import { MASTER_BASE_URL } from '../../../../API/FetchMaster';
import { Preview } from '../../../Generic/ImagePreviewer/Preview';

{/*
{
    "name": name,
    "model": model,
    "ai_algorithm": ai_algorithm,
    "ip_address": ip_address,
    "streaming_url": streaming_url,
    "unit": unit,
    "location": location,
    "status": status,
    "coordinates": coordinates,
    "created_at": created_at,
}
*/}

interface ICameraDetailsProps {
  camera: any;
  open: boolean;
  close: () => void;
  onDelete: (camera_id: string) => void;
}

export const CameraDetails = (props: ICameraDetailsProps) => {
  const [show, setShow] = useState(false);
  const { globalState: { permissions } } = React.useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [pageVars, setPageVars] = useState({
    acknowledgement: null,
    showImage: false
  })

  useEffect(() => {
    if (props.open) {

      setShow(true);
      // setPageVars({ ...pageVars, showImage: true })

    }
    else {

      setShow(false);
      // setPageVars({ ...pageVars, showImage: false })

    }
  }, [props.open]);

  const handleDelete = () => {
    try {
      props.onDelete(props.camera.id);
    }
    catch (err) {

    }
  }

  return (
    <div>{show &&
      <div className={'camera-details-pop-up-container'}>
        <div className={'close-btn'}>
          <img
            src={'/images/notifications/close.svg'}
            alt={'close.svg'}
            onClick={() => props.close()}
          />
        </div>
        <div className={'title'}>
          {'Camera Details'}
        </div>
        <div className={'camera-overview'}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}> {props.camera.name} </div>

          </div>

        </div>
        <div className={'pop-up-overview'}>

          <div className={'pop-up-details'}>
            <span>Model:</span>
            <span>{props.camera?.model}</span>
          </div>
          <div className={'pop-up-details'}>
            <span>AI algorithm Status:</span>
            <span>{props.camera?.ai_algorithm}</span>
          </div>
          <div className={'pop-up-details'}>
            <span>Ip address:</span>
            <span>{props.camera?.ip_address}</span>
          </div>
          <div className={'pop-up-details'}>
            <span>Unit:</span>
            <span>{props.camera?.unit}</span>
          </div>

          <div className={'pop-up-details'}>
            <span>Location:</span>
            <span>{props.camera.location}</span>
          </div>
          <div className={'pop-up-details'}>
            <span>Created on:</span>
            <span>{props.camera.created_at}</span>
          </div>
        </div>

        <div className={'pop-up-file'}>
          <div className={'pop-up-file-title'}>
            Virtual Fence
          </div>
          {props.camera.image_url ?
            <div className={'pop-up-file-container'}>
              <div className={'pop-up-file-details'}>
                <div className={'pop-up-file-img'}>
                  <img
                    src={`${MASTER_BASE_URL}${props.camera?.image_url}`}
                    alt={'pdf.svg'}
                  />
                </div>
                {/* <div className={'pop-up-file-name-size'}>
                        <div>
                            file
                        </div>
                        <div>
                            25KB
                        </div>
                    </div> */}
              </div>
              <div className={'pop-up-file-name-download'}>
                <a href={'#'}
                  onClick={() => {
                    setPageVars({ ...pageVars, showImage: true })
                  }}
                  style={{ textDecoration: 'none' }}>
                  view
                </a>
              </div>
            </div> : 'N/A'}
        </div>
        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '5rem', gap: '1rem' }}>
          {[...permissions].includes("CAMERA_UPDATE") &&

            <Button
              class='medium-btn-container'
              text={'Edit'}
              textcolor={'white'}
              bgcolor={'#28C670'}
              width={'30%'}
              onClick={() => {
                navigate(`${ROUTES.updateCamera}/${props.camera.id}`)
              }}
            />
          }

          {[...permissions].includes("CAMERA_DELETE") &&

            <Button
              class='medium-btn-container'
              text={'Delete'}
              textcolor={'white'}
              bgcolor={'#7B0323'}
              width={'30%'}
              onClick={handleDelete}
            />
          }
        </div>

      </div>
    }

      {/* <Preview
        previewImage={`${props.camera?.image_url}`}
        isOpen={pageVars.showImage}
        onClose={() => setPageVars({ ...pageVars, showImage: false })}
      /> */}


      <>{pageVars.showImage &&
        <div className={'preview-container'}>
          <div style={{ color: 'white', display: 'flex', justifyContent: 'flex-end' }}>
            <img
              src={'/images/notifications/closewhite.svg'}
              alt={'close.svg'}
              onClick={() => setPageVars({ ...pageVars, showImage: false })}
            />
          </div>
          <div className={'preview-image-container'}>
            <img
              src={props.camera.image_url != null ? (MASTER_BASE_URL  + props.camera?.image_url) : '/images/login/profileLogo.svg'}
              alt={'No Preview'}
            />
          </div>
        </div>
      }
      </>
    </div>
  );
};
