import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../Generic/Button/Button';
import { TextArea } from '../../Generic/TextArea/TextArea';
import TextInput from '../../Generic/TextInput/TextInput';
import { createVisitorActivity } from '../../../API/Visitors';
import './entry-visitor.scss';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import {toast} from 'sonner';
import Dropdown from '../../Generic/Dropdown/Dropdown';
import { FileUpload, UploadType } from '../../Generic/FileUpload/FileUpload';

export interface IAllowEntryVisitorProps {
    moveToActivityPage: ()=>void
}

export function AllowEntryVisitor (props: IAllowEntryVisitorProps) {
    const { globalState: {entryExitVisitor, user} } = React.useContext(GlobalStateContext);
    const form = {
        no_of_passengers: '0',
        relation_with_passengers: '',
        remarks: '',
        approved_by:'',
        vehicle_number: null,
        file: ''
     }
    const [formData, setFormData] = useState({...form});
    const [invalidFields, setInvalidFields] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [showVehicleDropDown, setShowVehicleDropDown] = useState(true);
    const validateFields = React.useCallback(async (valid: boolean, field: string) => {

        if(valid) {
            if(invalidFields.includes(field))
            setInvalidFields(Fields => { 
                let filtered = Fields.filter( value => value !== field );
                return filtered;
            });
        }
        else {
            if(!invalidFields.includes(field))
                setInvalidFields(Fields => { 
                    Fields.push(field);
                    return Fields;
                });
        }
      }, []);

    const submitForm = async () => {
        try {
            if(invalidFields.length) {
                toast.warning('Please fill all the required fields!');
                setSubmitClicked(true);
                return;
            }
            const payload = {
                visitor_id: entryExitVisitor.visitor_id,
                primary_visitor_id: entryExitVisitor.visitor_id,
                approved_by: formData.approved_by,
                vehicle_number: formData.vehicle_number,
                relation_with_passengers: formData.relation_with_passengers,
                no_of_passengers: formData.no_of_passengers,
                activity: 'IN',
                remarks: formData.remarks,
                gate_number: user.gateNumber,
                file: formData.file
            }
            const response = await createVisitorActivity({ ...payload });
            if(response && response.statusCode === 200) {
                setFormData(form);
                toast.success("Success: Gate entry allowed!")
                props.moveToActivityPage();
            }
            else throw new Error("Error");
        }
        catch(err) {
            toast.error("Error: Failed to allow gate entry!")
        }
    }

  return (
    <div className={'entry-visitor-container'}>
        <div className={'entry-visitor-title'}>
            Allow Entry
        </div>
        <div className={'entry-visitor-form-row-1'}>
            <TextInput
                label={'Name'}
                placeholder={'Enter visitor name'}
                width={'25rem'}
                key={'RegisterNewVisitor-name'}
                value={entryExitVisitor.fullname}
                disabled={true}
            />
            <TextInput
                label={'Category'}
                placeholder={'Enter category'}
                width={'25rem'}
                key={'RegisterNewVisitor-Category'}
                value={entryExitVisitor.category}
                disabled={true}
            />
            
            <div>
                {showVehicleDropDown ?
                <Dropdown
                    label={'Vehicle number'}
                    placeholder={'Select vehicle number'}
                    width={'25rem'}
                    options={entryExitVisitor.vehicle_number ? entryExitVisitor.vehicle_number.split(',') : []}
                    onSelect={(value) => {
                        setFormData({...formData, vehicle_number: value})
                    }}
                    selection={entryExitVisitor.vehicle_number?.split(',')[0]}
                /> 
                :
                <TextInput
                    label={'Vehicle number'}
                    placeholder={'Enter vehicle number'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-Vehicle'}
                    value={formData.vehicle_number}
                    onChange={
                        (value) => setFormData({...formData, vehicle_number: value})
                    }
                /> }
                <a style={{textDecoration:'none', color:'green'}} href={'#'} onClick={()=>setShowVehicleDropDown(!showVehicleDropDown)}>
                    {showVehicleDropDown ? `+ Add another vehicle number` : `Cancel`}
                </a>
            </div>

        </div>
        <div className={'entry-visitor-form-row-2'}>
            <TextInput
                label={'No.of passengers'}
                placeholder={'Enter no.of passengers'}
                width={'25rem'}
                key={'RegisterNewVisitor-no-passengers'}
                onChange={(value) => setFormData({...formData, no_of_passengers: value})}
                value={formData.no_of_passengers}
                isRequired={false}
                // isValid={validateFields}
                // showWarning={submitClicked}
                isNumber={true}
            />
            <TextInput
                label={'Relation with passengers'}
                placeholder={'Enter Relation with passengers'}
                width={'25rem'}
                key={'RegisterNewVisitor-Relation-passengers'}
                onChange={(value) => setFormData({...formData, relation_with_passengers: value})}
                // value={formData.relation_with_passengers}
            />
            <TextInput
                label={'Approved by'}
                placeholder={'Enter approved by'}
                width={'25rem'}
                key={'RegisterNewVisitor-Approved-by'}
                onChange={(value) => setFormData({...formData, approved_by: value})}
                value={formData.approved_by}
                isRequired={false}
                // isValid={validateFields}
                // showWarning={submitClicked}
            />
        </div>
        <div className={'entry-visitor-form-row-text-area'}>
            <TextArea
                label={'Remarks'}
                placeholder={'Enter visitors Remarks..'}
                width={'25rem'}
                height={'5rem'}
                key={'RegisterNewVisitor-Remarks'}
                onChange={(value)=>setFormData({...formData, remarks: value})}
                value={formData.remarks}
            />
            <FileUpload
                label={'Document / file'}
                width={'25rem'}
                accept={UploadType.file}
                onUpload={(file_url) => setFormData({ ...formData, file: file_url })}
                url={formData.file}
                showWarning={submitClicked}
            />
        </div>
        <div className={'register-visitor-btn-place'}>
            <Button
            class='btn-container'
                bgcolor={'#28C670'}
                text={'Allow entry'}
                textcolor={'white'}
                width={'10rem'}
                onClick={submitForm}
            />
        </div>
    </div>
  );
}
