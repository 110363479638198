import * as React from 'react';
import { SearchBar } from '../../Generic/SearchBar/SearchBar';
import { GenericTable } from '../../Generic/Table';
import './visitors-activities.scss';
import { ActivityDetailsSideCard } from './ActivityDetailsSideCard/ActivityDetailsSideCard';
import { Filters } from '../../Generic/Filters/Filters';
import { getVisitorActivities } from '../../../API/Visitors';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { useState } from 'react';
import { Button } from '../../Generic/Button/Button';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { VisitorsReport } from '../../Generic/Report/VisitorsActivityReport/VisitorsActivityReport';
import moment from 'moment-timezone';
import { Preview } from '../../Generic/ImagePreviewer/Preview';


export interface IVisitorsActivitiesProps {
}

export function VisitorsActivities(props: IVisitorsActivitiesProps) {
  const [pageVariables, setPageVariables] = React.useState({
    selectedActivity: null,
    openPopUp: false,
    openReportPopUp: false,
    tableData: [],
    loading: false,
    totalIn: 0,
    totalOut: 0
  });
  const [savedFilters, setSavedFilters] = useState({
    query: null,
    filters: null
  });

  // const [totalIn, setTotalIn] = useState(0);
  // const [totalOut, setTotalOut] = useState(0);

  const { globalState: { permissions, user } } = useContext(GlobalStateContext);
  const [resp, setResponse] = useState({})
  const [report, setReport] = useState(false)

  const [isAutoPullEnabled, setIsAutoPullEnabled] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const [filters, setFilters] = useState(null);
  const [query, setQuery] = useState(null);

  const handlePageChange = (newPage) => {
    setPage(newPage);

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const fetchVisitorsActivitiesList = async (p, filters = null, q) => {
    try {
      // setPageVariables({ ...pageVariables, loading: true });
      let options: any = {
        gate_number: user?.role === 'Guard' ? user?.gateNumber : undefined,
        page: page,
        query: query
      }
      if (filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          unit: filters.unit,
          category: filters.category,
          activity: filters.activity,
          gate_number: filters.gate_number || options.gate_number
        }
        setSavedFilters({ ...savedFilters, filters: options, query: query });
      }

      options = {
        ...options,
        page: page,
        limit: limit,
        query: query
      }

      const response = await getVisitorActivities(options);
      setResponse(response)
      setTotalItems(response.response.totalCounts)

      // prepare rows
      let dataRows = [];
      response.response.visitorDtos?.forEach(data => {
        const { visitor_id, id, fullname, category, unit, gender, vehicle_number, profile_image, gate_number, activity, approved_by, relation_with_passengers, no_of_passengers, timestamp, remarks, file } = data;

        dataRows = [...dataRows,
        {
          "id": id,
          "visitor_id": visitor_id,
          "fullname": fullname,
          "profile_image": profile_image,
          "gender": gender,
          "unit": unit,
          "category": category,
          "gate_number": gate_number,
          "activity": activity,
          "approved_by": approved_by,
          "vehicle_number": vehicle_number,
          "relation_with_passengers": relation_with_passengers,
          "no_of_passengers": no_of_passengers,
          "timestamp": timestamp,
          "remarks": remarks,
          "file": file
        }
        ];

      })
      setPageVariables({ ...pageVariables, tableData: dataRows, loading: false, totalIn: response.response.totalIn, totalOut: response.response.totalOut , selectedActivity: null});
    }
    catch (err) {
      setPageVariables({ ...pageVariables, loading: false, selectedActivity: null });
    }
  }

  const tableDemoData = {
    columnDetails: [
      {
        title: 'Profile',
        sortable: true,
        bold: true,
        key: "fullname"
      },
      {
        title: 'Passengers',
        key: "no_of_passengers"
      },
      {
        title: 'Timestamp',
        key: "timestamp"
      },
      {
        title: 'Gate No.',
        key: "gate_number"
      },
      {
        title: 'Activity Type',
        inOut: true,
        key: "activity"
      },
      {
        title: 'Category',
        key: "category"
      },

    ]
  }

  const exportPDF = async () => {

    const doc = new jsPDF()
    let tableBodyData = [];

    await pageVariables.tableData.forEach(data => {
      let tableRow = [];
      tableDemoData.columnDetails.forEach(column => {
        tableRow.push(data[column.key]);
      });
      tableBodyData.push(tableRow);
    });

    autoTable(doc, {
      head: [tableDemoData.columnDetails.map(data => { return data.title })],
      body: tableBodyData
    });

    doc.save('download' + '.pdf')
    alert("Report is downloaded. Please check downloads")

  }


  const getTodaysReport = async () => {

    let filters = {
      activity: "",
      category: "",
      // end_date: "2024-05-31",
      end_date: moment(new Date().setDate(new Date().getDate() + 1)).tz('Asia/Kolkata').format('YYYY-MM-DD 00:00:00'),
      gate_number: "",
      start_date: moment().tz('Asia/Kolkata').format('YYYY-MM-DD 00:00:00'),
      // start_date: "2024-05-31",
      unit: ""
    }

    await fetchVisitorsActivitiesList(page, filters, query)
    setReport(true)
  }

  useEffect(() => {
    if (report) {
      exportPDF()
      fetchVisitorsActivitiesList(page, filters, query)
      setReport(false)
    }


  }, [report])

  useEffect(() => {
    fetchVisitorsActivitiesList(page, filters, query);
  }, []);


  useEffect(() => {
    if ((query == null || query == '') && page == 1 && filters == null) {
      setIsAutoPullEnabled(true)
    } else {
      setIsAutoPullEnabled(false)

    }
    fetchVisitorsActivitiesList(page, filters, query);
  }, [page, limit, query, filters]);


  useEffect(() => {
    let intervalId;
    if (isAutoPullEnabled && !pageVariables.openPopUp) {
      intervalId = setInterval(fetchVisitorsActivitiesList, 20000); // Fetch every 5 seconds
    }
    return () => clearInterval(intervalId);
  }, [isAutoPullEnabled, pageVariables.openPopUp]);




  return (
    <div className={'visitors-profile-container'}>
      <div className={'visitors-profile-header'}>
        <div className={'visitors-profile-header-left'}>
          <div className={'title'}>
            In/Out Register
          </div>
          <div className={'title'}>
            <span style={{ margin: '0 1em' }}>
              (Total In : {pageVariables.totalIn}

            </span>
            <span style={{ margin: '0 1em' }}>
              Total Out: {pageVariables.totalOut})

            </span>
          </div>



        </div>
        <div style={{ display: 'flex', marginRight: '2rem' }}>
          <div>
            <SearchBar
              placeholder={'Search activity by visitors name, phone no.'}
              width={'20rem'}
              onSearch={(query) => setQuery(query)}
            />
          </div>

          <Filters
            unitFilter={false}
            categoryFilter={true}
            activityFilter={true}
            gateFilter={true}
            dateFilter={true}
            apply={filters => setFilters(filters)}
          />
        </div>
      </div>
      {[...permissions].includes("ANALYTICS_REPORTS") &&
        <div style={{ display: 'flex' }}>
          <div>
            <Button
              class='medium-btn-container'
              bgcolor={'#410e0b'}
              text={'Download Report'}
              textcolor={'white'}
              width={'10rem'}
              onClick={exportPDF}
            />
          </div>
          <div style={{ marginLeft: '1rem' }}>
            <Button
              class='medium-btn-container'
              bgcolor={'#410e0b'}
              text={'Infographics'}
              textcolor={'white'}
              width={'fit-content'}
              onClick={() => setPageVariables({ ...pageVariables, openReportPopUp: true })}
            />
          </div>
          <div style={{ display: 'flex', marginLeft: '1rem' }}>
            <Button
              class='medium-btn-container'
              bgcolor={'#410e0b'}
              text={'Today\'s Report'}
              textcolor={'white'}
              width={'fit-content'}
              onClick={() => getTodaysReport()}
            />
          </div>
        </div>}
      <GenericTable
        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVariables.tableData}
        selectedRow={(value) => setPageVariables(
          {
            ...pageVariables,
            selectedActivity: value,
            openPopUp: true
          })}

        loading={false}
        pagination={true}
        currentPage={page}
        totalItems={totalItems}
        initialLimit={limit}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
      {pageVariables.selectedActivity  && <ActivityDetailsSideCard
        open={pageVariables.openPopUp}
        activity={pageVariables.selectedActivity}
        fetchActivities = {()=>fetchVisitorsActivitiesList(page, filters, query)}
        close={() => {
          setPageVariables({ ...pageVariables, openPopUp: false })
        }}
      />}
      {pageVariables.openReportPopUp ?
        <VisitorsReport
          close={() => setPageVariables({ ...pageVariables, openReportPopUp: false })}

          response={resp}
        /> : ''}

    </div>
  );
}
