import * as React from 'react';
import { Button } from '../../../Generic/Button/Button';
import './activity-details-side-card.scss';
import { Preview } from '../../../Generic/ImagePreviewer/Preview';
import { useState } from 'react';
import { MASTER, MASTER_BASE_URL, SLAVE_BASE_URL } from '../../../../API/FetchMaster';
import { deleteVisitorActivity } from '../../../../API/Visitors';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { ROUTES } from '../../../Routes/SideBarRoutes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

export interface IActivityDetailsSideCardProps {
    activity: any;
    open: boolean;
    close: ()=>void;
    fetchActivities: ()=>void;
}

export const  ActivityDetailsSideCard = (props: IActivityDetailsSideCardProps) => {

    
    const { globalState: { permissions } } = React.useContext(GlobalStateContext);
    const navigate = useNavigate();

    const [show, setShow] = React.useState(false);
    const {id, visitor_id, fullname, profile_image, category,unit, gender, gate_number, activity, approved_by, vehicle_number, relation_with_passengers, no_of_passengers, timestamp, remarks, file} = props.activity;
    const [preview, setPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(false);

    const handleDelete = async (id)=>{
        try {
            const response = await deleteVisitorActivity(id)

            if(response && response.statusCode === 200) {

                toast.success("Success: deleted!");
                
                props.fetchActivities()

                props.close()

            }
            else throw new Error("Error");
        }
        catch(err) {
            toast.error("Error: Failed to fetch entry!")
        }
    }

    
    React.useEffect(()=>{
        if(props.open) {
            setShow(true);
            setPreview(true);

        }
        else { 
            setShow(false);
            setPreview(false);
        }
    },[props.open]);
    
  return (
    <div>{show&&
      <div className={'activity-details-pop-up-container'}>
          <div className={'close-btn'}>
              <img
                src={'/images/notifications/close.svg'}
                alt={'close.svg'}
                onClick={()=>props.close()}
              />
            </div>
          {/* <div className={'pop-up-title'}>
            {'Activity Details'}
          </div> */}
            <div className={'visitor-details'}>
                <div className={"image-row"}>
                    
                    <div>
                        <img
                            src={profile_image ? MASTER_BASE_URL +  profile_image : '/images/login/profileLogo.svg'}
                            alt={profile_image}
                            style={{width:'3rem', height:'3rem', objectFit:'cover', borderRadius:'0.3rem'}}
                        />
                        <div className={'visitor-name'}>
                            {fullname}
                        </div>
                    </div>
                    {/* <div className={'visitor-copy'}>
                        Copy
                    </div> */}

                </div>
                <div>
                    <div className={'pop-up-details'}>
                        <span>Visitor Id : </span>
                        <span>{id}</span>
                    </div>
                    <div className={'pop-up-details'}>
                        <span>Gender : </span>
                        <span>{gender}</span>
                    </div>
                    <div className={'pop-up-details'}>
                        <span>Unit : </span>
                        <span>{unit}</span>
                    </div>
                    <div className={'pop-up-details'}>
                        <span>Category :</span>
                        <span>{category}</span>
                    </div> 
                </div>
            </div>
          <div className={'pop-up-overview'}>
            <div className={'pop-up-overview-title'}>
                <div>
                    Activity Details 
                </div>
                <div style={{
                    backgroundColor: activity=== 'IN' ? '#28C670':'#7B0323',
                    color: 'white',
                }
                    }>
                    <div
                        style={{
                            backgroundColor: 'white',
                        }}
                    >

                    </div>
                    <div>
                        {activity}
                    </div>
                </div>
            </div>
            <div className={'pop-up-overview-message'}>
                <p>Details:</p>
                <p>{'No. of passengers : '+ no_of_passengers}</p>
                <p>{'Date & Time : '+ timestamp}</p>
            </div>
      
             <div className={'pop-up-details'}>
                <span>Vehicle Number:</span>
                <span>{vehicle_number}</span>
            </div> 
            <div className={'pop-up-details'}>
                <span>Relation With Passengers:</span>
                <span>{relation_with_passengers}</span>
            </div>
       
            <div className={'pop-up-details'}>
                <span>Approved by:</span>
                <span>{approved_by}</span>
            </div>
            <div className={'pop-up-details'}>
                <span>Remarks:</span>
                <span>{remarks}</span>
            </div>
          </div>
          
          {file ?
          <div className={'pop-up-file'}>
            <div className={'pop-up-file-title'}>
                Documents
            </div>
            <div className={'pop-up-file-container'}>
                <div className={'pop-up-file-details'}>
                    <div className={'pop-up-file-img'}>
                        <img 
                            src={file ? ((MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL) + file) : '/images/login/profileLogo.svg'}
                            alt={`click view to preview`}
                        />
                    </div>
                </div>
                <div 
                    className={'pop-up-file-name-download'}
                    onClick={()=>setPreviewFile(true)}
                >
                    View
                </div>
            </div>
          
          </div>
          :''}

        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '5rem', gap: '1rem' }}>
          {[...permissions].includes("ACTIVITY_ADD") &&

            <Button
              class='medium-btn-container'
              text={'Edit'}
              textcolor={'white'}
              bgcolor={'#28C670'}
              width={'30%'}
              onClick={() => {
                navigate(`${ROUTES.updateActivty}/${id}`)
              }}
            />
          }

          {[...permissions].includes("ACTIVITY_ADD") &&

            <Button
              class='medium-btn-container'
              text={'Delete'}
              textcolor={'white'}
              bgcolor={'#7B0323'}
              width={'30%'}
              onClick={()=>handleDelete(id)}
            />
          }
        </div>
      </div>
      
      }
 
     <div>
          <Preview
            previewImage={profile_image}
            isOpen={preview}
            onClose={()=>setPreview(false)}
          />
           <Preview
            previewImage={file}
            isOpen={previewFile}
            onClose={()=>setPreviewFile(false)}
          />
      </div>
      </div>)
}
