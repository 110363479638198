import * as React from 'react';
import { useState } from 'react';
import { GateList } from './GateList/GateList';
import { AddNewGate } from './AddNewGate/AddNewGate';
import { AddNewCredential } from './AddNewCredential/AddNewCredential';
import './gate-management.scss';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import CompanyComponent from '../Generic/CompanyLogo/CompanyComponents';

export interface IGateManagementProps {
}

export function GateManagement(props: IGateManagementProps) {
  const { globalState: { permissions } } = React.useContext(GlobalStateContext);

  const sidePanelRoutes = [
    {
      id: '#1',
      title: 'Gates',
      component: <GateList />,
      accessPermission: 'GATE_ALL',
    },
    {
      id: '#2',
      title: '+ Add New Gate',
      component: <AddNewGate moveToListPage={() => setPageVariables({ ...pageVariables, selectedRoute: sidePanelRoutes[0] })} />,
      accessPermission: 'GATE_ADD',
    },
    {
      id: '#3',
      title: '+ Add New Credentials',
      component: <AddNewCredential />,
      accessPermission: 'GATE_CREDS',
    }
  ];
  const [pageVariables, setPageVariables] = useState({
    selectedRoute: sidePanelRoutes[0]
  });
  React.useEffect(() => {
    document.title = 'Gates'
  }, []);

  return (
    <div className={'gate-management-container'}>
      <div className={'visitor-side-panel-container'}>
        <div className={'visitor-side-panel-routes-container'}>
          {
            sidePanelRoutes.map((route, index) => {
              return [...permissions].includes(route.accessPermission) && (
                <div
                  className={`visitor-side-panel-route-box 
                  ${pageVariables.selectedRoute.id === route.id ?
                      'selected-visitor-side-panel-route-box' : ''}`}
                  onClick={() => setPageVariables({ ...pageVariables, selectedRoute: route })}
                >
                  <div
                    className={pageVariables.selectedRoute.id === route.id ? 'selected-side-route' : 'not-selected-side-route'}
                  >
                  </div>
                  <div className={pageVariables.selectedRoute.id === route.id ? 'selected-visitor-side-panel-route' : 'visitor-side-panel-route'}>
                    {route.title}
                  </div>

                </div>)
            })
          }

        </div>
        <div className={'critical-ai-log-bottom'}>
           <CompanyComponent/>
        </div>
      </div>
      <div className={'visitor-content-container'}>
        {pageVariables.selectedRoute.component}
      </div>
    </div>
  );
}
