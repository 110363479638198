import * as React from 'react';
import { useState } from 'react';
import { RegisterNewVisitor } from './RegisterNewVisitor/RegisterNewVisitor';
import { AllowEntryVisitor } from './EntryVisitor/EntryVisitor';
import { AllowExitVisitor } from './ExitVisitor/ExitVisitor';
import './visitor-management.scss';
import { VisitorsActivities } from './VisitorsActivities/VisitorsActivities';
import { VisitorsList } from './VisitorsList/VisitorsList';
import { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import CompanyComponent from '../Generic/CompanyLogo/CompanyComponents';

export interface IVisitorManagementProps {
}

export function VisitorManagement (props: IVisitorManagementProps) {
  const {globalState:{permissions}} = useContext(GlobalStateContext);
  

const sidePanelRoutes = [
  {
    id: '#1',
    title:'Visitors',
    visible: true,
    component: <VisitorsList showEntryForm={() => {
      setPageVariables({...pageVariables, selectedRoute:  sidePanelRoutes[3]})}} 
      showExitForm={() => setPageVariables({...pageVariables, selectedRoute:  sidePanelRoutes[4]})}
      />,
    accessPermission: 'VISITOR_ALL',
  },
  {
    id: '#2',
    title:'In/Out Register',
    visible: true,
    component: <VisitorsActivities/>,
    accessPermission: 'ACTIVITY_ALL', 
  },
  {
    id: '#3',
    title:'+ Add New Visitor',
    visible: true,
    component: <RegisterNewVisitor showVisitorListPage={()=>setPageVariables({...pageVariables, selectedRoute:  sidePanelRoutes[0]})}/>,
    accessPermission: 'VISITOR_ADD', 
  },
  {
    id: '#4',
    title:'Allow entry',
    visible: false,
    component: <AllowEntryVisitor moveToActivityPage={()=>setPageVariables({...pageVariables, selectedRoute:  sidePanelRoutes[1]})}/>
  },
  {
    id: '#5',
    title:'Allow exit',
    visible: false,
    component: <AllowExitVisitor moveToActivityPage={()=>setPageVariables({...pageVariables, selectedRoute:  sidePanelRoutes[1]})}/>
  }
];

const [pageVariables, setPageVariables] = useState({
  selectedRoute: sidePanelRoutes[0]
})

  React.useEffect(()=>{
    document.title='Visitors'
  },[]);
  
  return (
    <div className={'visitor-management-container'}>
      <div className={'visitor-side-panel-container'}>
        <div className={'visitor-side-panel-routes-container'}>
          <div>        {
            sidePanelRoutes.map((route, index) => {
              return ( (route.visible && [...permissions].includes(route.accessPermission)) &&
              <div 
                className={`visitor-side-panel-route-box 
                  ${pageVariables.selectedRoute.id === route.id ? 
                    'selected-visitor-side-panel-route-box' : ''}`}
                onClick={()=>setPageVariables({...pageVariables, selectedRoute: route})}
              >
                <div 
                  className={pageVariables.selectedRoute.id === route.id ? 'selected-side-route' : 'not-selected-side-route'}
                >
                </div>
                <div className={pageVariables.selectedRoute.id === route.id ? 'selected-visitor-side-panel-route' : 'visitor-side-panel-route'}>
                  {route.title}
                </div>
              </div>)
            })
          }
          </div>
  

        </div>
        <div className={'critical-ai-log-bottom'}>
           <CompanyComponent/>
        </div>
      </div>
      <div className={'visitor-content-container'}>
        {pageVariables.selectedRoute.component}
      </div>
    </div>
  );
}
