import * as React from 'react';
import { GenericTable } from '../../Generic/Table';
import './notification-list.scss';
import { Button } from '../../Generic/Button/Button';
import { NotificationDetails } from '../NotificationDetailsPopUp/NotificationDetailsPopUp';
import { useState } from 'react';
import { getNotifications } from '../../../API/Notifications';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { GlobalDispatchContext, GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { ACTIONS } from '../../../GlobalContext/GlobalReducer';
import { SearchBar } from '../../Generic/SearchBar/SearchBar';
import { Filters } from '../../Generic/Filters/Filters';


export interface INotificationListProps {
}

export function NotificationList(props: INotificationListProps) {
  const { globalState: { syncNotifications, user } } = React.useContext(GlobalStateContext);
  const [isAutoPullEnabled, setIsAutoPullEnabled] = useState(true);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [filters, setFilters] = useState(null);
  const [query, setQuery] = useState(null);

  const handlePageChange = (newPage) => {
    setPage(newPage);

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const globalDispatcher = React.useContext(GlobalDispatchContext);
  const [pageVariables, setPageVariables] = useState({
    selectedNotification: null,
    openPopUp: false,
    tableData: [],
    loading: false,
  });

  const tableDemoData = {
    columnDetails: [
      {
        title: 'Title',
        sortable: true,
        key: 'title'
      },
      {
        title: 'Type',
        key: 'type'
      },
      {
        title: 'Gates',
        key: 'gate_numbers'
      },
      {
        title: 'Created By',
        key: 'created_by'
      },
      {
        title: 'Created On',
        key: 'created_at'
      }]
  };

  const fetchNotifications = async (p, filters = null, q) => {
    try {
      // setPageVariables({...pageVariables, loading: true, query: query, filters:filters});
      let options = {};
      if (filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          unit: filters.unit,
          category: filters.category,
          gate_number: filters.gate_number
        }
      }
      options = {
        ...options,
        page: page,
        query: query,
        limit: limit,

        gate_number: user.role === "Guard" ? user.gateNumber : null,
        user_id: user.id
      }

      const response = await getNotifications(options);

      if (response && response.statusCode === 200) {
        let dataRows = [];
        setTotalItems(response.response.totalCounts)

        response.response.notificationDtos?.forEach(data => {
          const { id, title, gate_numbers, type, message, file, created_by, created_at } = data;

          dataRows = [...dataRows,
          {
            "id": id,
            "title": title,
            "gate_numbers": gate_numbers?.join(','),
            "type": type,
            "message": message,
            "file": file,
            "created_by": created_by,
            "created_at": created_at,
          }];

        });
          setPageVariables({ ...pageVariables, tableData: dataRows, loading: false });
      }
      else throw new Error("Error");
    }
    catch (err) {
      toast.error("Error: failed to fetch notifications!")
      setPageVariables({ ...pageVariables, loading: false });
    }
  }

  useEffect(() => {
    fetchNotifications(page, filters, query);
  }, []);


  useEffect(() => {
    if ((query == null || query == '') && page == 1 && filters == null) {
      setIsAutoPullEnabled(true)
    } else {
      setIsAutoPullEnabled(false)
    }

    fetchNotifications(page, filters, query);
   

  }, [page, query, filters]);


  useEffect(() => {
    let intervalId;
    if (isAutoPullEnabled && !pageVariables.openPopUp) {
      // Set up an interval to fetch data periodically
      intervalId = setInterval(fetchNotifications, 20000); // Fetch every 5 seconds
    }
    return () => clearInterval(intervalId);
  }, [isAutoPullEnabled, pageVariables.openPopUp]);





  useEffect(() => {
    if (syncNotifications) {
      fetchNotifications(page, filters, query);
      globalDispatcher({ type: ACTIONS.setSyncNotifications, booleanPayload: false });
    }
  }, [syncNotifications]);


  React.useEffect(() => {
    fetchNotifications(page, filters, query);
  }, [page, limit]);


  return (
    <div className={'notification-list-container'}>
      <div className={'visitors-profile-header'}>
        <div className={'visitors-profile-header-left'}>
          <div className={'title'}>
            Notifications List
          </div>
          <div style={{ display: 'flex', marginRight: '2rem' }}>
            <div>
              {/* <SearchBar
                placeholder={'Search notification by title'}
                width={'20rem'}
                onSearch={query => setQuery(query)}
              /> */}
            </div>
            <Filters
              unitFilter={false}
              categoryFilter={false}
              gateFilter={false}
              dateFilter={true}
              apply={(filters) => setFilters(filters)}
            />
          </div>
          {/* <div className={'visitors-profile-header-update'}>
            Last updated on {new Date().toDateString()}
          </div> */}
        </div>
      </div>
      <GenericTable
        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVariables.tableData}
        selectedRow={(value) => setPageVariables(
          {
            ...pageVariables,
            selectedNotification: value,
            openPopUp: true
          })}
        loading={false}
        pagination={true}
        currentPage={page}
        totalItems={totalItems}
        initialLimit={limit}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
      <NotificationDetails
        notification={pageVariables.selectedNotification}
        open={pageVariables.openPopUp}
        close={() => setPageVariables({ ...pageVariables, openPopUp: false })}
      />
    </div>
  );
}
