import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { disableVisitor, getVisitorActivitiesByVisitor, getVisitorById } from '../../../API/Visitors';
import { Button } from '../../Generic/Button/Button';
import { Filters } from '../../Generic/Filters/Filters';
import { GenericTable } from '../../Generic/Table';
import { ROUTES } from '../../Routes/SideBarRoutes';
import { ActivityDetailsSideCard } from '../VisitorsActivities/ActivityDetailsSideCard/ActivityDetailsSideCard';
import './visitor-details.scss';
import { BlockVisitorCard } from './BlockVisitorPop/BlockVisitor';
import { toast } from 'sonner';
import { MASTER, MASTER_BASE_URL, SLAVE_BASE_URL } from '../../../API/FetchMaster';
import { Preview } from '../../Generic/ImagePreviewer/Preview';

export interface IVisitorDetailsProps {
}

export function VisitorDetails(props: IVisitorDetailsProps) {
  const navigate = useNavigate();
  const { visitor_id } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [query, setQuery] = useState(null);

  const handlePageChange = (newPage) => {
    setPage(newPage);

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };



  const [pageVars, setPageVars] = useState({
    id: null,
    salutation: null,
    fullname: null,
    phone_number: null,
    gender: null,
    id_card: null,
    profile_image: null,
    unit: null,
    category: null,
    address: null,
    vehicle_number: null,
    is_primary: null,
    created_at: null,
    selectedActivity: null,
    is_active: false,

    openPopUp: false,
    openBlockPopUp: false,
  });
  const [tableVars, setTableVars] = useState({
    tableData: [],
    totalIn: 0,
    totalOut: 0,
    loading: false
  });
  const [loaders, setLoaders] = useState({
    updatingVisitors: false
  });


  const [view, setView] = React.useState({
    acknowledgement: null,
    showImage: false
  })

  const [viewIdCard, setViewIdCard] = React.useState({
    acknowledgement: null,
    showImage: false
  })

  const tableDemoData = {
    columnDetails: [
      {
        title: 'Timestamp',
        key: "timestamp"
      },
      {
        title: 'Activity Type',
        inOut: true,
        key: "activity"
      },
      {
        title: 'Passengers',
        key: "no_of_passengers"
      },
      {
        title: 'Gate Number',
        key: "gate_number"
      },
      {
        title: 'Vehicle Number',
        key: "vehicle_number"
      },


      {
        title: 'Approved By',
        key: "approved_by"
      },
    ]
  }
  const navigateBack = () => {
    navigate(ROUTES.visitor)
  }
  const fetchActivities = async (p , filters, query?) => {
    try {
      setTableVars({ ...tableVars, loading: true });
      let options = {

      }
      if (filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          activity: filters.activity,
          gate_number: filters.gate_number,
        }
      }
      options = {
        ...options,
        page: page,
        visitor_id: visitor_id,
        query: query
      }

      options = {
        ...options,
        page: page,
        limit: limit,
        query: query
      }





      const response = await getVisitorActivitiesByVisitor(options);
      if (response && response.statusCode === 200) {
        let dataRows = [];
        setTotalItems(response.response.totalCounts)

        response.response.visitorDtos.forEach(visit => {
          const { id, fullname, profile_image, category, activity, vehicle_number, approved_by, relation_with_passengers,
            no_of_passengers, timestamp, gate_number, visitor_id
          } = visit;
          dataRows = [...dataRows, {
            "id": id,
            "visitor_id": visitor_id,
            "fullname": fullname,
            "gender": pageVars.gender,
            "unit": pageVars.unit,
            "profile_image": profile_image,
            "category": category,
            "gate_number": gate_number,
            "activity": activity,
            "approved_by": approved_by,
            "vehicle_number": vehicle_number,
            "relation_with_passengers": relation_with_passengers,
            "no_of_passengers": no_of_passengers,
            "timestamp": timestamp,
          }]
        });

        setTableVars({
          ...tableVars,
          tableData: dataRows,
          totalIn: response.response.totalIn,
          totalOut: response.response.totalOut,
          loading: false
        });
      }
      else throw new Error("Error");

    }
    catch (err) {
      setTableVars({ ...tableVars, loading: false });
    }
  }
  const getVisitorDetails = async () => {
    try {
      const response = await getVisitorById(visitor_id);

      if (response && response.statusCode === 200) {
        const {
          visitor_id,
          salutation,
          fullname,
          phone_number,
          gender,
          id_card,
          profile_image,
          unit,
          category,
          address,
          vehicle_number,
          is_primary,
          created_at,
          is_active
        } = response.response;
        setPageVars({
          ...pageVars,
          salutation,
          fullname,
          phone_number,
          gender,
          id_card,
          profile_image,
          unit,
          category,
          address,
          vehicle_number,
          is_primary,
          created_at,
          is_active,
          openBlockPopUp: false,
        });
      }
      else throw new Error("Error");

    }
    catch (err) {

    }
  }

  const blockVisitor = async () => {
    let is_active = pageVars.is_active;
    try {
      setLoaders({ ...loaders, updatingVisitors: true });
      const response = await disableVisitor(visitor_id, pageVars.is_active);
      if (response && response.statusCode === 200) {
        toast.success(`Success: visitor ${is_active ? 'blocked' : 'unblocked'}!`)
        setLoaders({ ...loaders, updatingVisitors: false });
        setPageVars({ ...pageVars, openBlockPopUp: false });
        getVisitorDetails();
      }
      else throw new Error("Error");
    }
    catch (err) {
      setLoaders({ ...loaders, updatingVisitors: false });
      toast.success(`Error: failed to  ${is_active ? 'blocked' : 'unblocked'} visitor!`)
    }
  }



  React.useEffect(() => {
    getVisitorDetails();
    setTableVars({ ...tableVars, loading: true });
    fetchActivities(page, null);
  }, []);


  React.useEffect(() => {
    fetchActivities(page, null);
  }, [page, limit]);




  return (
    <div>
      <div className={'visitor-details-container'}>
        <div className={'visitor-details-left-panel'}>
          {/* <div style={{
            padding: '1rem',
            fontSize: '1.5rem',
            fontWeight: 'bold'
          }}>
            Visitor Profile Page
          </div> */}
          <div className={'visitor-details-left-panel-back-btn'}>
            <Button
              class='medium-btn-container'
              text={'< Back to visitors list'}
              textcolor={'dark-green'}
              bgcolor={'#09784433'}
              width={'fit-content'}
              onClick={navigateBack}
            />
          </div>
          <div className={'profile-image-name-section'}>
            <div
              className={'profile-image'}
            >
              <img style={{ width: '10rem', height: '10rem', objectFit: 'cover', borderRadius: '1rem' }}
                onClick={() => {
                  setView({ ...view, showImage: true })
                }}
                src={pageVars.profile_image ? ((MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL) + pageVars.profile_image) : '/images/login/profileLogo.svg'}

              />
            </div>

            <div>

              <div className={'visitor-name'}>
                {pageVars.salutation ? pageVars.salutation + " " + pageVars.fullname : pageVars.fullname}
              </div>
              <div className={'visitor-status'}>
                <span className={pageVars.is_active ? 'status-active' : 'status-blocked'}> {pageVars.is_active ? 'Active' : 'Blocked'} </span>
              </div>
            </div>
          </div>
          <div className={'pop-up-file'}>
            <div className={'pop-up-file-title'}>
              ID Card
            </div>

            <div>
              {pageVars.id_card ?
                <div className={'pop-up-file-container'}>
                  <div className={'pop-up-file-details'}>
                    <div className={'pop-up-file-img'}>
                      <img
                        src={(MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL) + pageVars.id_card || '/images/login/profileLogo.svg'}
                        alt={'pdf.svg'}
                      />
                    </div>

                  </div>
                  <div className={'pop-up-file-name-download'}>
                    <a href={'#'}
                      onClick={() => {
                        setViewIdCard({ ...viewIdCard, showImage: true })
                      }}
                      style={{ textDecoration: 'none' }}>
                      View ID Card
                    </a>
                  </div>
                </div> : 'N/A'}
            </div>

          </div>

          <div className={'personal-details-section'}>
            <div className={'personal-details-section-header'}>
              <div>Personal Details</div>
              <div className={'personal-details-section-header-btns'}>
                <Button
                  class='medium-btn-container'
                  text={'Edit'}
                  textcolor={'white'}
                  bgcolor={'#28C670'}
                  width={'fil-content'}
                  onClick={() => navigate(ROUTES.updateVisitor + '/' + visitor_id)}
                />
                {pageVars.is_active ?
                  <Button
                    class='medium-btn-container'
                    text={'Block'}
                    textcolor={'white'}
                    bgcolor={'#7B0323'}
                    width={'fil-content'}
                    onClick={() => setPageVars({ ...pageVars, openBlockPopUp: !pageVars.openBlockPopUp })}
                  /> :
                  <Button
                    class='medium-btn-container'
                    text={'Unblock'}
                    textcolor={'white'}
                    bgcolor={'#28C670'}
                    width={'fil-content'}
                    onClick={() => setPageVars({ ...pageVars, openBlockPopUp: !pageVars.openBlockPopUp })}
                  />
                }
              </div>
            </div>

            <div className='personal-details'>
              <div className='personal-details-div'>
                <span>Full Name</span> : <span>{pageVars.salutation ? pageVars.salutation + " " + pageVars.fullname : pageVars.fullname}</span>
              </div>
              <div className='personal-details-div'>
                <span>Phone no./ID no.</span> : <span>{pageVars.phone_number}</span>
              </div>
              <div className='personal-details-div'>
                <span>Gender</span> : <span>{pageVars.gender}</span>
              </div>
              <div className='personal-details-div'>
                <span>Category</span> : <span>{pageVars.category}</span>
              </div>
              <div className='personal-details-div'>
                <span>Unit</span> : <span>{pageVars.unit}</span>
              </div>
              <div className='personal-details-div'>
                <span>Vehicle Number</span> : <span>{pageVars.vehicle_number}</span>
              </div>
              <div className='personal-details-div'>
                <span>Registration time</span> : <span>{pageVars.created_at}</span>
              </div>
              {/* <div className='personal-details-div'>
                <span>Id card</span> :


                <div className={'pop-up-file-name-download'}>

                  <img style={{ width: '10rem', height: '10rem', objectFit: 'cover', borderRadius: '1rem' }} src={MASTER_BASE_URL + pageVars.id_card} />
                  <a href={MASTER_BASE_URL + pageVars.id_card} target={'_blank'}>
                    download
                  </a>
                </div>
              </div> */}


            </div>
          </div>


          <div>

          </div>

        </div>
        <div className={'overview-container'}>
          <div className={'overview-dashboard'}>
            <div className={'overview-dashboard-left'}>
              <div style={{ fontWeight: 'bold', fontSize: '1.5rem', height: 'fit-content', width: 'fit-content' }}>Overview</div>
            </div>
            <div className={'overview-dashboard-family-member'}>
              <p>Total In</p>
              <p>{tableVars.totalIn}</p>
            </div>
            <div className={'overview-dashboard-family-member'}>
              <p>Total Out</p>
              <p>{tableVars.totalOut}</p>
            </div>
          </div>
          <div className={'activities-list'}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Activities</div>
              <div>
                <div style={{ display: 'flex' }}>
                  <Filters
                    activityFilter={true}
                    gateFilter={true}
                    dateFilter={true}
                    apply={(filters)=>fetchActivities(page, filters)}
                  />
                </div>
              </div>
            </div>
            <div className={'activity-card'} >
              <GenericTable
                columnDetails={tableDemoData.columnDetails}
                dataRows={tableVars.tableData}
                selectedRow={(row) => setPageVars({ ...pageVars, selectedActivity: row, openPopUp: true })}
                loading={tableVars.loading}
                pagination={true}
                currentPage={page}
                totalItems={totalItems}
                initialLimit={limit}
                onPageChange={handlePageChange}
                onLimitChange={handleLimitChange}
              />
            </div>
          </div>


          <Preview
            previewImage={`${pageVars?.profile_image}`}
            isOpen={view.showImage}
            onClose={() => setView({ ...view, showImage: false })}
          />
          <Preview
            previewImage={`${pageVars?.id_card}`}
            isOpen={viewIdCard.showImage}
            onClose={() => setViewIdCard({ ...view, showImage: false })}
          />

        </div>




        {pageVars.selectedActivity && <ActivityDetailsSideCard
          open={pageVars.openPopUp}
          fetchActivities={()=>   fetchActivities(page, null)}
          activity={{ ...pageVars.selectedActivity, gender: pageVars.gender, unit: pageVars.unit }}
          close={() => setPageVars({ ...pageVars, openPopUp: false })}
        />}
        {pageVars.openBlockPopUp ?
          <BlockVisitorCard
            block={pageVars.is_active}
            visitor={pageVars}
            close={() => setPageVars({ ...pageVars, openBlockPopUp: false })}
            confirm={blockVisitor}
            open={pageVars.openBlockPopUp}
            updating={loaders.updatingVisitors}
          /> : ''}

      </div>

    </div>
  );
}
