import * as React from 'react';
import './visitors-activity-report.scss';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import Loader from '../../Loader/Loader';
import { useState } from 'react';
import { getVisitorActivities } from '../../../../API/Visitors';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button } from '../../Button/Button';
import moment from 'moment';


interface IVisitorsReportProps {
    close: () => void;
    response: { response: { visitorDtos: [] } }
}

export const VisitorsReport = (props) => {
    const [pageVars, setPageVars] = useState({
        loading: false,
        pieChartData: [],
        pieChartData2: []

    });
    const [savedFilters, setSavedFilters] = useState({
        query: null,
        filters: null
    });

    const fetchVisitorsActivitiesList = async (page, filters, query?) => {
        try {
            setPageVars({ ...pageVars, loading: true });
            let options: any = {
                page: page,
                query: query
            }
            if (filters) {
                options = {
                    ...options,
                    start_date: filters.start_date,
                    end_date: filters.end_date,
                    unit: filters.unit,
                    category: filters.category,
                    activity: filters.activity,
                    gate_number: filters.gate_number || options.gate_number
                }
                setSavedFilters({ ...savedFilters, filters: options, query: query });
            }

            const response = props.response

            // prepare rows
            let map = {};
            let in_out_map = {}
            response.response.visitorDtos?.forEach(data => {
                const { visitor_id, fullname, category, unit, gender, vehicle_number, profile_image, gate_number, activity, approved_by, relation_with_passengers, no_of_passengers, timestamp, remarks } = data;
                if (map[category]) {
                    map[category] += 1;
                }
                else {
                    map[category] = 1;
                }

                if (in_out_map[activity] != null) {
                    in_out_map[activity] += 1
                }
                else {
                    in_out_map[activity] = 1
                }

            });

            let pieChatDataSet = [];
            let pieChatDataSet2 = [];

            Object.keys(map).forEach((key, index) => {
                pieChatDataSet.push({
                    id: index + 1,
                    value: map[key],
                    label: key
                })
            });
            Object.keys(in_out_map).forEach((key, index) => {
                pieChatDataSet2.push({
                    id: index + 1,
                    value: in_out_map[key],
                    label: key
                })
            });

            setPageVars({ ...pageVars, loading: false, pieChartData: pieChatDataSet, pieChartData2: pieChatDataSet2 });
        }
        catch (err) {
            setPageVars({ ...pageVars, loading: false, });
        }
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const downloadPDF = () => {
        const input = document.getElementById('png-content');
        const date = moment().tz('Asia/Kolkata').format('YYYY-MM-DD')
        html2canvas(input).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `Infographics-Report-${date}.png`;
            link.click();
        });
        alert("Report is downloaded. Please check downloads")
    };


    useEffect(() => {
        fetchVisitorsActivitiesList(1, null);
    }, []);


    return <div className={'visitor-report-container'}>
        <div className={'visitor-report-popup'}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', backgroundColor: 'gray' }}>
                <div className={'close-image'} style={{ height: 'fit-content', width: 'fit-content', cursor: 'pointer' }}>
                    <img
                        style={{ height: '2rem', width: '2rem' }}
                        src={'/images/notifications/closewhite.svg'}
                        alt={'close.svg'}
                        onClick={() => props.close()}
                    />
                </div>
            </div>

            {!pageVars.loading ?
                <div >
                    <div style={{ margin: '1rem', display: 'flex', justifyContent: 'center' }}>
                        <Button
                            class='medium-btn-container'
                            bgcolor={'#28C670'}
                            text={'Download Infographics'}
                            textcolor={'white'}
                            width={'fit-content'}
                            onClick={() => downloadPDF()}
                        />
                    </div>
                    <div id="png-content" style={{ padding: '2rem 0', marginTop: '1rem' }}>
                        <div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    // fontWeight: '530',
                                    marginBottom: '2rem'

                                }}
                            >
                                Total In/Out activities by Category
                            </div>


                            <Box sx={{ height: 400 }}>

                                <PieChart
                                    series={[
                                        {
                                            data: pageVars.pieChartData.map(item => ({
                                                id: item.id,
                                                value: item.value,
                                                label: `${item.label}: ${item.value}`,
                                            })),
                                            // arcLabel: (item) => `${item.label}`,
                                            arcLabelMinAngle: 45,
                                        },
                                    ]}

                                    width={1000}
                                    height={300}
                                    colors={['#7FFFD4', '#DDA0DD', '#F0E68C', '#6699CC', '#ADD8E6', '#11BFD8', '#8FB38B', '#7C39E8', '#73FFD4', '#DDA0D1', '#F0E38C', '#6699C1', '#AD38E6', '#D8BFD8', '#83BC82', '#7C39E8',]}
                                ></PieChart>
                            </Box>
                        </div>
                        <div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontWeight: '500',
                                    marginTop: '2rem',
                                }}
                            >
                                Total In/Out 
                            </div>
                            <PieChart
                                series={[
                                    {
                                        data: pageVars.pieChartData2.map(item => ({
                                            id: item.id,
                                            value: item.value,
                                            label: `${item.label}: ${item.value}`,
                                        })),
                                        // arcLabel: (item) => `${item.label}`,
                                        arcLabelMinAngle: 45,
                                    },
                                ]}

                                width={1000}
                                height={300}
                                colors={['#7FFFD4', '#DDA0DD']}
                            />
                        </div>
                    </div>
                    {/* <div style={{display:'flex', padding:'2rem 0', marginTop:'1rem'}}>

                <div>
                    <div
                        style={{
                            textAlign:'center',
                            fontWeight:'500'
                        }}
                    >
                        Total In/Out activities by Category
                    </div>
                    <PieChart
                        series={[
                            {
                            data: pageVars.pieChartData,
                            },
                        ]}
                        width={500}
                        height={200}
                        colors={[ '#7FFFD4', '#DDA0DD','#F0E68C', '#6699CC', '#ADD8E6', '#D8BFD8', '#8FBC8B', '#7CB9E8',]}
                    />
                </div>
                <div>
                    <div
                        style={{
                            textAlign:'center',
                            fontWeight:'500'
                        }}
                    >
                        Total In/Out activities by Category
                    </div>
                    <PieChart
                        series={[
                            {
                            data: pageVars.pieChartData,
                            },
                        ]}
                        width={500}
                        height={200}
                        colors={[ '#7FFFD4', '#DDA0DD','#F0E68C', '#6699CC', '#ADD8E6', '#D8BFD8', '#8FBC8B', '#7CB9E8',]}
                    />
                </div>
            </div> */}
                    {/* <div>
                <div
                    style={{
                        textAlign:'center',
                        fontWeight:'500'
                    }}
                >
                    Total In/Out activities per day
                </div>
                <BarChart
                    xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
                    series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
                    width={500}
                    height={300}

                />
            </div> */}
                </div> :
                <Loader
                    loadingText={'Loading...'}
                    height='90%'
                />
            }
        </div>
    </div>;
};

