import React from 'react';
import TextInput from '../Generic/TextInput/TextInput';
import { TextArea } from '../Generic/TextArea/TextArea';
import { FileUpload, UploadType } from '../Generic/FileUpload/FileUpload';
import { useState } from 'react';
import { getCompany, updateCompany } from '../../API/Company';
import { Button } from '../Generic/Button/Button';
import { toast } from 'sonner';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import { ACTIONS } from '../../GlobalContext/GlobalReducer';
import { MASTER_BASE_URL } from '../../API/FetchMaster';
export const UserCompany = () => {
    const [pageVars, setPageVars] = useState({
        title: '',
        description: '',
        logo_url: '',
        showForm: false
    });
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        logo_url: '',
    });

    const {globalState:{user}} = useContext(GlobalStateContext);
    const globalDispatcher = useContext(GlobalDispatchContext);

    const [invalidFields, setInvalidFields] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const validateFields = React.useCallback(async (valid: boolean, field: string) => {

        if (valid) {
          if (invalidFields.includes(field))
            setInvalidFields(Fields => {
              let filtered = Fields.filter(value => value !== field);
              return filtered;
            });
        }
        else {
          if (!invalidFields.includes(field))
            setInvalidFields(Fields => {
              Fields.push(field);
              return Fields;
            });
        }
      }, []);

    const updateDetails = async () => {
        try {
            if (invalidFields.length) {
                toast.warning('Please fill all the required fields!');
                setSubmitClicked(true);
                return;
              }
            const payload = {
                id: user.company_id,
                title: formData.title,
                description: formData.description,
                logo_url: formData.logo_url
            }
            const response = await updateCompany(payload);

            if(response && response.statusCode === 200) {
                toast.success("Successfully updated!");
                globalDispatcher({type: ACTIONS.setCompany, payload: response.response});
                setPageVars({...pageVars, showForm: false});
            }
            else throw new Error("Error");
            

        }
        catch(err) {
            toast.error("Error: failed to update!");
        }
    }

    const fetchDetails = async () => {
        try {
            const response = await getCompany(user.company_id);
            if(response && response.statusCode === 200) {
                const {description, logo_url, title} = response.response;
                setPageVars({
                    ...pageVars,
                    title: title,
                    description: description,
                    logo_url: logo_url
                });
                setFormData({
                    ...formData,
                    title: title,
                    description: description,
                    logo_url: logo_url
                })
            }
        }
        catch(err) {
            toast.error("Error: failed to fetch company details");
        }
    }

    useEffect(()=>{
        fetchDetails();
    },[]);


    return (
        <React.Fragment>
        {!pageVars.showForm ?
        <div style={{padding:'2rem', display:'flex', flexDirection:'column', gap:'2rem'}}>
            <div style={{display:'flex', gap:'2rem'}}>
                <div>
                    <img
                        style={{height:'12rem', width:'12rem'}}
                        src={MASTER_BASE_URL + pageVars.logo_url || 'images/side_bar/bytecoder.svg'}
                    />
                </div>
                <div style={{height:'fit-content', width:'fit-content', margin:'auto 2rem'}}>
                    <div style={{fontSize:'1.2rem', fontWeight:'bold'}}>
                        {pageVars.title}
                    </div>
                    <div style={{fontSize:'0.9rem', fontStyle:'italic'}}>
                        {pageVars.description}
                    </div>
                </div>
            </div>
            <Button
            class='medium-btn-container'
                bgcolor={'#28C670'}
                text={'Edit'}
                textcolor={'white'}
                width={'fit-content'}
                onClick={()=>setPageVars({...pageVars, showForm: true})}
            />
        </div>
        :
        <div style={{padding:'2rem', display:'flex', flexDirection:'column', gap:'2rem'}}>
            <TextInput
                label={'Title'}
                placeholder={'Enter title'}
                width={'32rem'}
                isRequired={true}
                value={formData.title}
                onChange={(value)=>setFormData({...formData, title: value})}
                isValid={validateFields}
                showWarning={submitClicked}
            />
            <TextArea
                label={'Description'}
                placeholder={'Enter description'}
                width={'30rem'}
                height={'7rem'}
                onChange={(value)=>setFormData({...formData, description: value})}
                isRequired={false}
                value={formData.description}
                // isValid={validateFields}
                // showWarning={submitClicked}
            />
            <FileUpload
                label={'Upload Logo'}
                width={'30rem'}
                accept={UploadType.image}
                isRequired={true}
                onUpload={(url) => setFormData({...formData, logo_url:url})}
                url={formData.logo_url}
                // isValid={validateFields}
                // showWarning={submitClicked}
            />
            <div style={{display:'flex', justifyContent:'left'}}>
                <Button
                class='medium-btn-container'
                    bgcolor={'#28C670'}
                    text={'Update'}
                    textcolor={'white'}
                    width={'fit-content'}
                    onClick={updateDetails}

                />
                <Button
                class='medium-btn-container'
                    bgcolor={'white'}
                    text={'Cancel'}
                    textcolor={'#28C670'}
                    width={'fit-content'}
                    onClick={()=>setPageVars({...pageVars, showForm: false})}
                />
            </div>
        </div>}
        </React.Fragment>
    )
}