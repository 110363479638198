import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalStateContext } from '../../../../../GlobalContext/GlobalContextProvider';
import { Button } from '../../../../Generic/Button/Button';
import { ROUTES } from '../../../../Routes/SideBarRoutes';
import './unit-details.scss';

{/*
{
    "name": name,
    "model": model,
    "ai_algorithm": ai_algorithm,
    "ip_address": ip_address,
    "streaming_url": streaming_url,
    "unit": unit,
    "location": location,
    "status": status,
    "coordinates": coordinates,
    "created_at": created_at,
}
*/}

interface ICameraDetailsProps {
  camera: any;
  open: boolean;
  close: () => void;
  onDelete: (camera_id: string) => void;
}

export const CameraDetails = (props: ICameraDetailsProps) => {
  const [show, setShow] = useState(false);
  const { globalState: { permissions } } = React.useContext(GlobalStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.open) setShow(true);
    else setShow(false);
  }, [props.open]);

  const handleDelete = () => {
    try {
      props.onDelete(props.camera.id);
    }
    catch (err) {

    }
  }

  return (
    <div>{show &&
      <div className={'unit-details-pop-up-container'}>
        <div className={'close-btn'}>
          <img
            src={'/images/notifications/close.svg'}
            alt={'close.svg'}
            onClick={() => props.close()}
          />
        </div>
        <div className={'title'}>
          {'Unit Details'}
        </div>
        <div className={'camera-overview'}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}> {props.camera.title} </div>

          </div>

        </div>
        <div className={'pop-up-overview'}>
          {/* <div className={'pop-up-overview-title'}>
                Overview
            </div> */}
          <div className={'pop-up-details'}>
            <span>Description:</span>
            <span>{props.camera?.description}</span>
          </div>
          {/* <div className={'pop-up-details'}>
                <span>Outtime:</span>
                <span>{props.camera?.outtime}</span>
            </div> */}
          <div className={'pop-up-details'}>
            <span>Created At:</span>
            <span>{props.camera?.createdAt}</span>
          </div>
          {/* <div className={'pop-up-details'}>
                <span>Updated At:</span>
                <span>{props.camera?.updatedAt}</span>
            </div> */}

        </div>

        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '5rem', gap: '1rem' }}>
          {[...permissions].includes("CONFIGS_UPDATE") &&

            <Button
              class='medium-btn-container'
              text={'Edit'}
              textcolor={'white'}
              bgcolor={'#28C670'}
              width={'30%'}
              onClick={() => {
                navigate(`${ROUTES.updateUnit}/${props.camera.id}`)
              }}
            />
          }
          {[...permissions].includes("CONFIGS_DELETE") &&

            <Button
              class='medium-btn-container'
              text={'Delete'}
              textcolor={'white'}
              bgcolor={'#7B0323'}
              width={'30%'}
              onClick={handleDelete}
            />
          }
        </div>

      </div>
    }
    </div>
  );
};
