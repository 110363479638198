import "./App.scss";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import { SideBar } from "./components/SideBar/SideBar";
import { Header } from "./components/Header/Header";
import { ROUTES } from "./components/Routes/SideBarRoutes";
import { VisitorManagement } from "./components/VisitorManagement/VisitorManagement";
import { GateManagement } from './components/GateManagement/GateManagement';
import { CameraManagement } from './components/CameraManagement/CameraManagenet';
import { NotificationManagement } from './components/NotificationManagement/NotificationsManagement';
import { VisitorDetails } from './components/VisitorManagement/VisitorDetails/VisitorDetails';
import { GateDetails } from './components/GateManagement/GateDetails/GateDetails';
import { LOCAL_STORAGE_KEYS, Login } from './components/Login/Login';
import { GlobalDispatchContext, GlobalStateContext } from './GlobalContext/GlobalContextProvider';
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { ACTIONS } from "./GlobalContext/GlobalReducer";
import { toast, Toaster } from 'sonner';
import { getAllGates } from "./API/Gates";
import { UserCompany } from './components/UserCompany/UserCompany';
import { EditVisitor } from './components/VisitorManagement/EditVisitor/EditVisitor';
import { EditGate } from './components/GateManagement/EditGate/EditGate';
import { getCompany } from "./API/Company";
import { EditCamera } from './components/CameraManagement/EditCamera/EditCamera';
import { ConfigsManagement } from "./components/ConfigManagement/ConfigsManagenet";
import { NotificationAcknowledgement } from "./components/NotificationAck/NotificationAcknowledgementPop";
import { authorise_token } from "./API/Auth";
import { EditCategory } from "./components/ConfigManagement/Category/EditCategory/EditCategory";
import { EditUnit } from "./components/ConfigManagement/Unit/EditUnit/EditUnit";
import { EditUser } from "./components/ConfigManagement/Users/EditUser/EditUser";
import { AllowEntryVisitor } from "./components/VisitorManagement/EntryVisitor/EntryVisitor";
import { EditActivity } from "./components/VisitorManagement/EditActivity/EditActivity";

function App() {
  const { globalState: { loggedIn, user, popNotificationAlert } } = useContext(GlobalStateContext);
  const globalDispatcher = useContext(GlobalDispatchContext);
  const [pageVariables, setPageVariables] = useState({
    showLogOutMenu: false,
    showNotificationPop: false,
  });
  const userDropdown = useRef(null);

  const [networkStatus, setNetworkStatus] = useState(false)


  const findOutsideClick = (event) => {
    if (userDropdown.current && !userDropdown.current.contains(event.target)) {
      setPageVariables({ ...pageVariables, showLogOutMenu: false });
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", findOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", findOutsideClick);
    };
  }, [userDropdown]);

  useEffect(() => {
    if (loggedIn && popNotificationAlert) {
      setPageVariables({ ...pageVariables, showNotificationPop: true });
    }
    else setPageVariables({ ...pageVariables, showNotificationPop: false });
  }, [popNotificationAlert]);

  const logout = async () => {
    setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu });
    globalDispatcher({ type: ACTIONS.setLoggedIn, booleanPayload: false });
    localStorage.removeItem(LOCAL_STORAGE_KEYS.is_logged);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.permissions);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.stored_token);
  }

  useEffect(() => {
    let logged_in = localStorage.getItem(LOCAL_STORAGE_KEYS.is_logged);


    if (logged_in === 'true') {
      const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.user));
      const permissions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.permissions))
      globalDispatcher({ type: ACTIONS.setLoggedIn, booleanPayload: true });
      globalDispatcher({ type: ACTIONS.setLoggedInUser, payload: user });
      globalDispatcher({ type: ACTIONS.setPermissions, payload: permissions });
    }
  }, []);
  const setGates = async () => {
    try {
      const response = await getAllGates({ page: 1 });
      if (response && response.statusCode === 200) {
        globalDispatcher({ type: ACTIONS.setGates, payload: response.response.gateDtos });
      }

      const responseCompany = await getCompany(user.company_id);
      if (responseCompany && responseCompany.statusCode === 200) {
        globalDispatcher({ type: ACTIONS.setCompany, payload: responseCompany.response });
      }
    }
    catch (err) {
      toast.error("Error: failed to fetch gates!");
    }
  }

  const navigateToCompanyDetails = () => {
    setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu });
  }

  useEffect(() => {
    if (loggedIn) setGates();
  }, [loggedIn]);

  return (
    <div className="App">
      <Toaster position="top-right" duration={3000} />
      {
        loggedIn ?
          <Router>
            <SideBar />
            <div style={{ paddingLeft: '6rem', width: '100%',minHeight: '95vh' }}>
              <Header networkStatus={networkStatus} setNetworkStatus={(status)=>  setNetworkStatus(status)} profileClicked={() => setPageVariables({ ...pageVariables, showLogOutMenu: !pageVariables.showLogOutMenu })} />
              {pageVariables.showLogOutMenu &&
                <div className={'drop-down-menus'} ref={userDropdown}>
                  <div>{user?.username}</div>

                  {user && (user.role === "Admin") && 
                  <Link to={ROUTES.user} style={{ textDecoration: 'none', color: '#e5ecfd'       }}>
                    <div onClick={navigateToCompanyDetails}>{'Company'}</div>
                  </Link>}

                  <Link to={'/'} style={{ textDecoration: 'none', color: '#f77f7f' }}>
                    <div
                      style={{ color: '#f77f7f' }}
                      onClick={logout}
                    >
                      Logout
                    </div>
                  </Link>
                </div>}
              <div style={{ paddingTop: '4.5rem',minHeight: '100%',    backgroundColor:'#06335C' }}>
                <Routes>
                  <Route path={ROUTES.visitor} element={<VisitorManagement />} />
                  <Route path={ROUTES.gates} element={<GateManagement />} />
                  <Route path={ROUTES.cameras} element={<CameraManagement />} />
                  <Route path={ROUTES.configs} element={<ConfigsManagement />} />
                  <Route path={ROUTES.notifications} element={<NotificationManagement />} />
                  <Route path={ROUTES.visitorDetails + '/:visitor_id'} element={<VisitorDetails />} />
                  <Route path={ROUTES.gateDetails + '/:gate_number'} element={<GateDetails />} />
                  <Route path={ROUTES.updateVisitor + '/:visitor_id'} element={<EditVisitor />} />
                  <Route path={ROUTES.updateGate + '/:gate_id'} element={<EditGate />} />
                  <Route path={ROUTES.updateCamera + '/:camera_id'} element={<EditCamera />} />
                  <Route path={ROUTES.updateCategory + '/:id'} element={<EditCategory />} />
                  <Route path={ROUTES.updateUnit + '/:id'} element={<EditUnit />} />
                  <Route path={ROUTES.updateUser + '/:id'} element={<EditUser />} />
                  <Route path={ROUTES.updateActivty + '/:id'} element={<EditActivity  />} />
                  <Route path={ROUTES.user} element={<UserCompany />} />
                  <Route path="/" element={<Navigate to={ROUTES.visitor} />} />
                </Routes>
              </div>
            </div>
          </Router>
          :
          <Login />
      }
      {(loggedIn && pageVariables.showNotificationPop && user && user.is_notification_enabled && networkStatus) && <NotificationAcknowledgement /> }
    </div>
  );
}

export default App;
