import * as React from 'react';
import { CameraList } from './CameraList/CameraList';
import './camera-management.scss';
import { AddCamera } from './AddCamera/AddCamera';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import CompanyComponent from '../Generic/CompanyLogo/CompanyComponents';

export interface ICameraManagementProps {
}



export const CameraManagement = (props: ICameraManagementProps) => {
  const { globalState: { permissions } } = useContext(GlobalStateContext);

  const sidePanelRoutes = [
    {
      id: '#1',
      title: 'Cameras',
      component: <CameraList />,
      accessPermission: 'CAMERA_ALL'
    },
    {
      id: '#2',
      title: '+ Add New Camera',
      component: <AddCamera moveToListPage={() => setPageVariables({ ...pageVariables, selectedRoute: sidePanelRoutes[0] })} />,
      accessPermission: 'CAMERA_ADD'
    },
    // {
    //   id: '#3',
    //   title: 'Optical Cameras',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // }, {
    //   id: '#4',
    //   title: 'ANPR',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // }, 
    // {
    //   id: '#5',
    //   title: 'Thermal Cameras',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },  
    // {
    //   id: '#6',
    //   title: 'Drone Feed',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },  
    // {
    //   id: '#7',
    //   title: 'Biometric',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },  
    // {
    //   id: '#8',
    //   title: 'RFID',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },  
    // {
    //   id: '#9',
    //   title: 'Fire Alarm',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },
    // {
    //   id: '#10',
    //   title: 'Electric Fence',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },
    // {
    //   id: '#11',
    //   title: 'Metal Detectors',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },
    // {
    //   id: '#12',
    //   title: 'Anti Drone',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },  
    // {
    //   id: '#13',
    //   title: 'Sonar',
    //   component: <CameraList />,
    //   accessPermission: 'CAMERA_ALL'
    // },
  ];
  const [pageVariables, setPageVariables] = React.useState({
    selectedRoute: sidePanelRoutes[0]
  })

  useEffect(() => {
    document.title = 'Cameras'
  }, []);

  return (
    <div className={'camera-management-container'}>
      <div className={'camera-side-panel-container'}>
        <div className={'camera-side-panel-routes-container'}>
          {
            sidePanelRoutes.map((route, index) => {
              return [...permissions].includes(route.accessPermission) && (
                <div
                  className={`camera-side-panel-route-box 
                      ${pageVariables.selectedRoute.id === route.id ?
                      'selected-camera-side-panel-route-box' : ''}`}
                  onClick={() => setPageVariables({ ...pageVariables, selectedRoute: route })}
                >
                  <div
                    className={pageVariables.selectedRoute.id === route.id ? 'selected-side-route' : 'not-selected-side-route'}
                  >
                  </div>
                  <div className={pageVariables.selectedRoute.id === route.id ? 'selected-camera-side-panel-route' : 'camera-side-panel-route'}>
                    {route.title}
                  </div>

                </div>)
            })
          }

        </div>
        <div className={'critical-ai-log-bottom'}>
          <CompanyComponent />
        </div>
      </div>
      <div className={'camera-content-container'}>
        {pageVariables.selectedRoute.component}
      </div>
    </div>);
}
